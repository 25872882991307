import {FC, useEffect, useState} from 'react';

import {Tick} from '~/icons/Tick';
import {X, Info, AlertCircle } from 'lucide-react';
import {Toast} from 'flowbite-react';
import {createPortal} from 'react-dom';
import {useMountedState} from 'react-use';

const Status = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

interface TimedToastProps {
  isSuccess: boolean;
  message: string;
  timeout?: number;
  parentSetter?: (value: boolean) => void;
  position?: 'top' | 'bottom';
  status?: 'success' | 'error' | 'warning' | 'info';
}

const IconMapping = {
  [Status.success]: { icon: Tick, color: 'green-500' },
  [Status.error]: { icon: X, color: 'red-500' },
  [Status.warning]: { icon: AlertCircle, color: 'orange-500' },
  [Status.info]: { icon: Info, color: 'blue-500' },
}

export const TimedToast: FC<TimedToastProps> = ({
  isSuccess,
  message,
  timeout = 3000,
  parentSetter,
  position = 'bottom',
  status,
}) => {
  const isMounted = useMountedState();
  const [showSuccess, setShowSuccess] = useState(false);
  const IconComponent = status ? IconMapping[status] : null
  useEffect(() => {
    if (isSuccess && isMounted()) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        if (parentSetter) {
          parentSetter(false);
        }
      }, timeout);
    }
  }, [isSuccess, isMounted, timeout, parentSetter]);

  return showSuccess
    ? createPortal(
        <Toast
          className={`fixed ${
            position === 'bottom' ? 'bottom-2' : 'top-2'
          } right-2 max-w-[300px] bg-codGray50 z-[2147483002] border-4 border-solid border-${IconComponent ? IconComponent.color : 'feta800'}`}
        >
          <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg text-${IconComponent ? IconComponent.color : 'feta500'}`}>
            {IconComponent ? <IconComponent.icon size={16} className={`text-${IconComponent.color}`} /> : <Tick />}
          </div>
          <div className="ml-3 text-sm font-bold">{message}</div>
        </Toast>,
        document.body,
      )
    : null;
};
